import React from 'react';
import './App.css';

import Navigaion from './Navigation.js';

// Usual bootstrap stylingn
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <Navigaion/>
    </div>
  );
}

export default App;
